import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { graphql } from 'gatsby';
import { orderBy } from 'lodash';

import DataTestimonials from '../data/testimonials.json';
import DataProjects from '../data/projects.json';
import Layout from '../components/Layout';
import BackgroundSrc from '../img/svg/pageServices/hero_bg.svg';
import Illustration from '../img/svg/pageServices/hero_illustration.svg';
import IllustrationBarsSrc from '../img/svg/pageServices/hero_bars.svg';
import BackgroundClientsSrc from '../img/svg/pageHome/conference_bg.svg';
import ImageStarsSrc from '../img/svg/stars.svg';

import IconedText from '../components/IconedText';
import Markdown from '../components/Markdown';
import Blog from '../components/sections/Blog';
import ProjectItem from '../components/ProjectItem';
import ReferenceSwiper from '../components/ReferenceSwiper';
import Experience from '../components/sections/Experience';

export const ServicesPageTemplate = ({ 
  hero, 
  items,
  testimonial, 
  project,
  blogs,
  experience
}) => {

  const dataProjects =
    DataProjects.items.filter(o => project.projects.find(o2 => o.id === o2.value))
    .map(item => {
      let current = item.testimonial && item.testimonial[0] && item.testimonial[0].value;
      let testimonial =
        DataTestimonials.projects.find(o => current === o.id);

      return {...item, ...{testimonial: testimonial}}
  });

  return (
    <>
      <section className="ServicesHero">
        <div className="Layout__background">
          <SVG src={ImageStarsSrc} className="Layout__stars" />
        </div>

        <div className="ServicesHero__background">
          <SVG
            src={BackgroundSrc}
            uniquifyIDs={false}
            className="ServicesHero__background-image"
          />
        </div>

        <div className="Services__container ServicesHero__container">
          <div className="ServicesHero__content">
            <h1 className="ServicesHero__title">{hero.title}</h1>
            <p className="ServicesHero__text">{hero.text}</p>
          </div>

          <div className="ServicesHero__illustration">
            <img
              src={Illustration}
              alt="servers"
              className="ServicesHero__image"
            />
            <SVG src={IllustrationBarsSrc} className="ServicesHero__bars" />
          </div>
        </div>
      </section>

      <section className="Services__cards">
        <div className="Services__container">
          <ul className="Services__items">
            {items.map((item, i) => (
              <IconedText
                key={i}
                className="Services__item"
                tagName="li"
                iconSrc={item.icon.publicURL || item.icon}
                title={item.name}
                variants={['large']}
              >
                <Markdown content={item.description} />
              </IconedText>
            ))}
          </ul>
        </div>
      </section>

      <section className="Services__testimonials">
        <div className="Services__testimonials__background">
          <SVG
            src={BackgroundClientsSrc}
            uniquifyIDs={false}
            className="Services__testimonials__background-image"
          />
        </div>
        <div className="Services__testimonials__container">
          <h2 className="Services__testimonials__title">{testimonial.title}</h2>
          <ReferenceSwiper data={DataTestimonials.workshops} />
        </div>
      </section>

      <section className="Services__projects">
        <div className="Services__container">
          <h2 className="Services__projects__title">{project.title}</h2>
          {dataProjects.map((item, i) => (
            <div key={i} className="Services__projects__item">
              <ProjectItem
                title={item.title}
                imageSrc={item.image}
                buttons={item.buttons}
                technologies={item.technologies}
                reference={item.testimonial}
                reverse={i % 2 === 0 ? true : false}
              >
                {item.text}
              </ProjectItem>
            </div>
          ))}
        </div>
      </section>

      <Blog 
        className="Services__blog" 
        data={blogs}
      />

      <Experience 
        heading={experience.heading}
        items={experience.items}
      />
    </>
  )
};

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  let dataBlogs = data.blogs.edges.map(function(elem, i) {
    let slugFrontmatter = elem.node.frontmatter.slug;
    let slug = slugFrontmatter ? slugFrontmatter : elem.node.fields.slug;
      
    return {
      ...{id: elem.node.id}, 
      ...{excerpt: elem.node.excerpt}, 
      ...elem.node.frontmatter,
      category: elem.node.frontmatter.category,
      slug: slug // should be last 
    };
  });

  // order by 'order' and 'date' fields
  dataBlogs = orderBy(dataBlogs, [
    (item) => (item.order),
    (item) => (item.date)
  ], ["asc", "desc"]);

  const mergedData = {
    ...frontmatter,
    ...{blogs: dataBlogs}
  };

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <ServicesPageTemplate {...mergedData} />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ServicesPage;

export const servicesPageQuery = graphql`query ServicesPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "services-page"}}) {
    frontmatter {
      meta {
        title
        description
        image {
          publicURL
          name
        }
      }
      hero {
        title
        text
      }
      items {
        icon {
          publicURL
          name
        }
        name
        description
      }
      testimonial {
        title
      }
      experience {
        heading
        items {
          text
          title
          icon {
            publicURL
            name
          }
          slug
        }
      }
      project {
        title
        projects {
          value
        }
      }
    }
  }
  blogs: allMarkdownRemark(
    limit: 6
    sort: {frontmatter: {order: ASC}}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 150)
        id
        fields {
          slug
        }
        frontmatter {
          order
          date
          title
          description
          slug
          image {
            publicURL
            name
          }
          category
        }
      }
    }
  }
}`;
